<template>
  <div>
    <form-create
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        titulo="Nova Subdisciplina"
        @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Subdisciplinas
      </h5>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
              />

              <b-button
                  v-if="$can('campos-engenharia', 'subdisciplinas')"
                  variant="success"
                  @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          small
          :items="fetchDados"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          :busy="isLoad"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"/>
            <strong> Carregando...</strong>
          </div>
        </template>
        <template #cell(nome)="data">
          <small>{{ data.item.nome }}</small>
        </template>
        <template #cell(disciplinas)="data">
          <b-media vertical-align="center">
            <tag-color
                v-for="(item, index) in data.item.disciplinas"
                :key="index"
                :texto="item.abreviacao"
                :bg-color="item.bg_color"
                class="mb-1"
            />
          </b-media>
        </template>
        <template #cell(tarefas)="data">
          <div class="text-center">
            {{ data.item.favoritos_count }} | {{ data.item.tarefas.length }}
          </div>

        </template>
        <template #cell(formatos)="data">
          <div
              v-for="(item, index) in data.item.formatos"
              :key="index"
          ><small>{{
              item.descricao
            }}</small></div>
        </template>
        <template #cell(unidades_medida)="data">

          <div
              v-for="(item, index) in data.item.unidades_medida"
              :key="index"
          ><small>{{
              item.abreviacao
            }}</small></div>
        </template>
        <template #cell(valor)="data">
          <div class="font-size-8"

          >{{
              data.item.valor_bim ? formatarParaMoedaBrasileira(data.item.valor_bim) : 'R$ 0,00'
            }} | {{
              data.item.valor_cad ? formatarParaMoedaBrasileira(data.item.valor_cad) : 'R$ 0,00'
            }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
                v-if="$can('gerenciar-grupos', 'subdisciplinas')"
                :to="{ name: 'grupo-favoritos', params: { id: data.item.uuid } }"
            >
              <feather-icon icon="SlidersIcon"/>
              <span class="align-middle ml-50">Gerenciar Grupos</span>
            </b-dropdown-item>

            <b-dropdown-item @click="editarItem(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalItens"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
          id="modal-confirmacao"
          titulo="Excluir Item"
          :item-id="dadosItem.uuid"
          :item-nome="dadosItem.nome"
          @itemConfirmado="excluirItem"
          @click="modalExcluir(data.item)"
      />
    </b-card>
    <b-modal
        id="modal-editar"
        v-model="modalShow"
        :title="`Editar ${dadosItem.nome}`"
        size="lg"
    >
      <validation-provider
          #default="validationContext"
          name="Nome da Subdisciplina"
          rules="required"
      >
        <b-form-group
            label="Nome da Subdisciplina"
            label-for="nome-subdisciplina"
        >
          <b-form-input
              id="nome-subdisciplina"
              v-model="dadosItem.nome"
              name="subdisciplina"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Informe o nome da subdisciplina"
              :disabled="!$can('campos-engenharia', 'subdisciplinas')"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

        </b-form-group>
        <b-form-group
            v-if="dadosItem.listaTarefas.length > 0 && $can('campos-engenharia', 'subdisciplinas')"
            label="Tarefas Favoritas"
            label-for="tarefas-favoritas"
        >
          <v-select
              id="tarefas-favoritas"
              v-model="dadosItem.tarefasFavoritas"
              :options="dadosItem.listaTarefas"
              label="name"
              :clearable="false"
              input-id="tarefas-favoritas"
              placeholder="Escolha as tarefas"
              multiple
          />

        </b-form-group>
      </validation-provider>
      <validation-provider
          v-if="$can('valor-comercial', 'subdisciplinas')"
          #default="validationContext"
          name="Valor da disciplina CAD"
          rules="required"
      >
        <b-form-group
            label="Valor da Subdisciplina CAD"
            label-for="valor-subdisciplina"
        >
          <b-form-input
              id="valor-subdisciplina-cad"
              v-model="dadosItem.valor_cad"
              v-money="{precision: 2, decimal: ',', thousands: '.', prefix: 'R$ '}"
              name="valor-subdisciplina-cad"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Informe o valor"
          />
          <small class="text-muted" v-if="dadosItem.historico_cad">Valor anterior:
            {{ formatarParaMoedaBrasileira(dadosItem.historico_cad.valor) }} em
            {{ mascaraDataBr(dadosItem.historico_cad.created_at) }}</small>

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

        </b-form-group>

      </validation-provider>
      <validation-provider
          #default="validationContext"
          name="Unidade de medida"
          rules="required"
      >
        <b-form-group
            label="Unidades de medidas"
            label-for="unidades-medidas"
        >
          <select-com-pesquisa
              v-model="dadosItem.unidadesMedida"
              url="/unidades-medida"
              placeholder="Escolha as unidades de medidas"
              :multiple="true"
              :disabled="false"
              name="nome"
              :itens-para-edicao="dadosItem.unidadesMedida || []"
              @selecionados="tratarEventoUnidadeMedidasSelecionadas"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

        </b-form-group>

      </validation-provider>
      <b-form-group
          v-if="$can('comentario-comercial', 'subdisciplinas')"
          label="Descrição CAD"
          label-for="descricao-cad-subdisciplina"
      >
        <quill-editor-pensou :item-edicao="dadosItem.descricao_cad" toolbar-id="toolbar-cad"
                             @input="tratarDescricaoCad"
        />
      </b-form-group>


      <validation-provider
          v-if="$can('valor-comercial', 'subdisciplinas')"
          #default="validationContext"
          name="Valor da disciplina BIM"
          rules="required"
      >
        <b-form-group

            label="Valor da Subdisciplina BIM"
            label-for="valor-subdisciplina-bim"
        >
          <b-form-input
              id="valor-subdisciplina-bim"
              v-model="dadosItem.valor_bim"
              v-money="{precision: 2, decimal: ',', thousands: '.', prefix: 'R$ '}"
              name="valor-subdisciplinaBim"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Informe o valor"
          />
          <small class="text-muted" v-if="dadosItem.historico_bim">Valor anterior:
            {{ formatarParaMoedaBrasileira(dadosItem.historico_bim.valor) }} em
            {{ mascaraDataBr(dadosItem.historico_bim.created_at) }}</small>

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

        </b-form-group>

      </validation-provider>

      <b-form-group
          v-if="$can('comentario-comercial', 'subdisciplinas')"
          label="Descrição BIM"
          label-for="descricao-subdisciplina"
      >
        <quill-editor-pensou :item-edicao="dadosItem.descricao_bim" toolbar-id="toolbar-bim"
                             @input="tratarDescricaoBim"
        />

      </b-form-group>


      <template #modal-footer>
        <div class="w-100">

          <b-button
              variant="primary"
              size="md"
              class="float-left"
              @click="modalShow = false"
          >
            Fechar
          </b-button>
          <b-button
              variant="success"
              size="md"
              class="float-right"
              @click="salvarItemEditado(dadosItem)"
          >
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'

import { formatarParaMoedaBrasileira, limpaMoeda, mascaraDataBr } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import store from '@/store'
import subdisciplinasStoreModule from '../subdisciplinasStoreModule'
import TagColor from '@/views/components/componentesPensou/TagColor.vue'
import useItemList from './useItemList'
import vSelect from 'vue-select'
import FormCreate from './FormCreate.vue'
import QuillEditorPensou from '@/views/forms/form-element/quill-editor/QuillEditorPensou.vue'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'

export default {
  components: {
    SelectComPesquisa,
    QuillEditorPensou,
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    vSelect,
    ValidationProvider,
    TagColor,
  },

  setup() {
    const blankUserData = {
      nome: '',
    }

    const SUBDISCIPLINAS_APP_STORE_MODULE_NAME = 'app-subdisciplinas'

    // Register module
    if (!store.hasModule(SUBDISCIPLINAS_APP_STORE_MODULE_NAME)) store.registerModule(SUBDISCIPLINAS_APP_STORE_MODULE_NAME, subdisciplinasStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(SUBDISCIPLINAS_APP_STORE_MODULE_NAME)) store.unregisterModule(SUBDISCIPLINAS_APP_STORE_MODULE_NAME)
    // })

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const isAddNewSidebarActive = ref(false)
    const listaUsuarios = ref([])

    const {
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,
      update,
    } = useItemList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      isAddNewSidebarActive,
      listaUsuarios,
      deleteItem,
      fetchDados,
      update,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      refFormObserver,
      getValidationState,
      resetForm,
      itemData,
      limpaMoeda,

    }
  },
  data() {
    return {
      dadosItem: {
        uuid: '',
        nome: '',
        tarefasFavoritas: [],
        valor_bim: 0,
        valor_cad: 0,
        descricao_bim: '',
        descricao_cad: '',
      },
      modalShow: false,
    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-subdisciplinas'].load
    },
    items() {
      return this.fetchDados
    },
  },

  methods: {
    formatarParaMoedaBrasileira,
    mascaraDataBr,
    editarItem(item) {
      this.modalShow = true
      this.dadosItem.nome = item.nome
      this.dadosItem.uuid = item.uuid
      this.dadosItem.unidadesMedida = item.unidades_medida
      this.dadosItem.valor_cad = item.valor_cad || 0
      this.dadosItem.valor_bim = item.valor_bim || 0
      this.dadosItem.descricao_cad = item.descricao_cad
      this.dadosItem.descricao_bim = item.descricao_bim
      this.dadosItem.historico_bim = item.penultimo_historico_valor_bim
      this.dadosItem.historico_cad = item.penultimo_historico_valor_cad
      this.dadosItem.tarefasFavoritas = item.tarefas.filter(tarefa => tarefa.pivot.favorito)
          .map(tarefa => (
              {
                id: tarefa.uuid,
                name: tarefa.descricao,
              }

          ))
      this.dadosItem.listaTarefas = item.tarefas.map(tarefa => ({
        id: tarefa.uuid,
        name: tarefa.descricao,
      }))
    },

    excluirItem(dados) {
      this.deleteItem(dados)
    },

    modalExcluir(item) {
      this.dadosItem.nome = item.nome
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    tratarDescricaoCad(text) {
      this.dadosItem.descricao_cad = text
    },
    tratarDescricaoBim(text) {
      this.dadosItem.descricao_bim = text
    },
    salvarItemEditado(item) {

      this.$root.$emit('bv::hide::modal', 'modal-editar')
      const itemEditado = { ...item }
      itemEditado.valor_bim = limpaMoeda(itemEditado.valor_bim)
      itemEditado.valor_cad = limpaMoeda(itemEditado.valor_cad)
      this.update(itemEditado)
    },
    tratarEventoUnidadeMedidasSelecionadas(item) {
      this.dadosItem.unidadesMedida = item || []
    },
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
